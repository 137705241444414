<div class="main-banner-area bg-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="main-banner-content">
                    <span>We are here for</span>
                    <h1>We believe in those made to do more</h1>
                    <p>Loans are advantageous as a relatively inexpensive way of borrowing money. Start or grow your own business</p>
                    <div class="banner-btn">
                        <a routerLink="/apply-now" class="default-btn">View More<span></span></a>
                    </div>
                </div>
                <div class="banner-social-buttons">
                    <ul>
                        <li><span>Follow us</span></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/image1.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/image2.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/image3.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/image4.png" alt="image">
            </div>
            <div class="partner-item">
                <img src="assets/img/partner/image5.png" alt="image">
            </div>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-wrap">
                    <img src="assets/img/about/image3.png" alt="image">
                    <div class="about-shape">
                        <div class="text">
                            <h3>25</h3>
                            <span>Years Of Experience</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <span>About company</span>
                    <h3>We have been working very efficiently with loan and funding for 25 years.</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <strong>In business, we focus on 3 things.</strong>
                    <ul class="about-list">
                        <li><i class="flaticon-check"></i> Useful info</li>
                        <li><i class="flaticon-check"></i> Reliability</li>
                        <li><i class="flaticon-check"></i> Innovation</li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="/about" class="default-btn">View More<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="choose-title">
                    <span>Transparent process</span>
                    <h2>Why people choose us</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div class="choose-image">
                    <img src="assets/img/choose.png" alt="image">
                    <a href="https://www.youtube.com/watch?v=qaHWDmFtBl0" class="video-btn popup-youtube"><i class="flaticon-play-button"></i></a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top1">
                            <div class="icon">
                                <i class="flaticon-guarantee"></i>
                            </div>
                            <h3>Guarantee</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top2">
                            <div class="icon">
                                <i class="flaticon-speed"></i>
                            </div>
                            <h3>Speed</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top3">
                            <div class="icon">
                                <i class="flaticon-reliability"></i>
                            </div>
                            <h3>Reliability</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="process-item bg-top4">
                            <div class="icon">
                                <i class="flaticon-user-experience"></i>
                            </div>
                            <h3>Experience</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="deserve-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Funding circles</span>
            <h2>You deserve a better business loan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="deserve-item">
                    <h3>We usually follow 4 steps to get a better business loans.</h3>

                    <div class="deserve-content">
                        <span>1</span>
                        <h4>Apply in 10 minutes</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-content">
                        <span>2</span>
                        <h4>Hear from us in 1 hour</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-content">
                        <span>3</span>
                        <h4>A decision in 24 hours</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-content">
                        <span>4</span>
                        <h4>Your loan is funded</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-btn">
                        <a href="apply-now.html" class="default-btn">
                            Apply now
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="rate-form bg-fdebeb">
                    <div class="rate-content">
                        <span>Calculate your rate</span>
                        <h3>How much do you need?</h3>
                    </div>

                    <div class="form">
                        <div class="form-group mb-3">
                            <label>Type The Money</label>
                            <input type="text" class="form-control" placeholder="$1000">
                        </div>

                        <div class="form-group mb-3">
                            <label>Select The Month/Years</label>
                            <div class="select-box">
                                <select class="form-control">
                                    <option value="5">1 month</option>
                                    <option value="1">2 month</option>
                                    <option value="2">3 month</option>
                                    <option value="0">4 month</option>
                                    <option value="3">6 month</option>
                                    <option value="3">1 Year</option>
                                    <option value="0">2 Years</option>
                                    <option value="3">3 Years</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group mb-3">
                            <label>Borrowing</label>
                            <input type="text" class="form-control" placeholder="$10000">
                        </div>

                        <div class="form-group mb-3">
                            <label>Term of use</label>
                            <input type="text" class="form-control" placeholder="6 month">
                        </div>

                        <div class="form-group mb-3">
                            <label>The total you will pay</label>
                            <input type="text" class="form-control" placeholder="$11200">
                        </div>

                        <div class="rate-btn">
                            <button type="submit" class="default-btn">Apply for this loan<span></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="solution-area">
    <div class="container">
        <div class="solution-item">
            <div class="row align-items-center">
                <div class="col-lg-7">
                    <div class="content">
                        <h3>We are here to help your business Call to discuss your Solution</h3>
                    </div>
                </div>

                <div class="col-lg-5">
                    <div class="solution-info">
                        <i class="flaticon-telephone"></i>
                        <h3><a href="tel:15143125678">+1 (514) 312-5678</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="company-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="company-content">
                    <h3>Our company values</h3>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-idea"></i>
                        </div>
                        <h4>Innovative</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-talent"></i>
                        </div>
                        <h4>Talent</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-key"></i>
                        </div>
                        <h4>Enabling</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-responsibility"></i>
                        </div>
                        <h4>Commercially responsible</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="company-wrap">
                    <img src="assets/img/company2.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="best-services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Knowledge of the market</span>
            <h2>Only the best professional services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="best-services-slider">
            <div id="thumbs" class="owl-carousel owl-theme">
                <div class="tabs-item">
                    <a href="#">
                        <i class="flaticon-agriculture"></i>
                        <span>Agricultural loan</span>
                    </a>
                </div>

                <div class="tabs-item">
                    <a href="#">
                        <i class="flaticon-personal"></i>
                        <span>Personal loan</span>
                    </a>
                </div>

                <div class="tabs-item">
                    <a href="#">
                        <i class="flaticon-loan-1"></i>
                        <span>Business loan</span>
                    </a>
                </div>
                
                <div class="tabs-item">
                    <a href="#">
                        <i class="flaticon-scholarship"></i>
                        <span>Education loan</span>
                    </a>
                </div>

                <div class="tabs-item">
                    <a href="#">
                        <i class="flaticon-loan-2"></i>
                        <span>House loan</span>
                    </a>
                </div>

                <div class="tabs-item">
                    <a href="#">
                        <i class="flaticon-loan-3"></i>
                        <span>Payday loan</span>
                    </a>
                </div>

                <div class="tabs-item">
                    <a href="#">
                        <i class="flaticon-agriculture"></i>
                        <span>Agricultural loan</span>
                    </a>
                </div>
            </div>

            <div id="best-services" class="owl-carousel owl-theme">
                <div class="services-tabs-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-image">
                                <img src="assets/img/services-tab.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>Agricultural loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Types of business loan</h3>
                                <li>Secured loans</li>
                                <li>Unsecured loans</li>
                                <li>Revolving credit facilities</li>
                                <li>Business cash advances</li>
                            </ul>
                            <div class="services-tab-content">
                                <h3>Eligibility and criteria for Agricultural loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div class="services-tab-content left">
                                <h3>Agricultural loan guide</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="services-tabs-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-image">
                                <img src="assets/img/services-tab.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>Personal loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Types of business loan</h3>
                                <li>Secured loans</li>
                                <li>Unsecured loans</li>
                                <li>Revolving credit facilities</li>
                                <li>Business cash advances</li>
                            </ul>
                            <div class="services-tab-content">
                                <h3>Eligibility and criteria for Personal loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div class="services-tab-content left">
                                <h3>Personal loan guide</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="services-tabs-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-image">
                                <img src="assets/img/services-tab.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>Business loans</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Types of business loan</h3>
                                <li>Secured loans</li>
                                <li>Unsecured loans</li>
                                <li>Revolving credit facilities</li>
                                <li>Business cash advances</li>
                            </ul>
                            <div class="services-tab-content">
                                <h3>Eligibility and criteria for business loans</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div class="services-tab-content left">
                                <h3>Business loans guide</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="services-tabs-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-image">
                                <img src="assets/img/services-tab.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>Education loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Types of business loan</h3>
                                <li>Secured loans</li>
                                <li>Unsecured loans</li>
                                <li>Revolving credit facilities</li>
                                <li>Business cash advances</li>
                            </ul>
                            <div class="services-tab-content">
                                <h3>Eligibility and criteria for Education loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div class="services-tab-content left">
                                <h3>Education loan guide</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="services-tabs-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-image">
                                <img src="assets/img/services-tab.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>House loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Types of business loan</h3>
                                <li>Secured loans</li>
                                <li>Unsecured loans</li>
                                <li>Revolving credit facilities</li>
                                <li>Business cash advances</li>
                            </ul>
                            <div class="services-tab-content">
                                <h3>Eligibility and criteria for House loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div class="services-tab-content left">
                                <h3>House loan guide</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="services-tabs-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-image">
                                <img src="assets/img/services-tab.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>Payday loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Types of business loan</h3>
                                <li>Secured loans</li>
                                <li>Unsecured loans</li>
                                <li>Revolving credit facilities</li>
                                <li>Business cash advances</li>
                            </ul>
                            <div class="services-tab-content">
                                <h3>Eligibility and criteria for Payday loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div class="services-tab-content left">
                                <h3>Payday loan guide</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="services-tabs-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="services-tab-image">
                                <img src="assets/img/services-tab.png" alt="image">
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="services-tab-content">
                                <h3>Agricultural loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                            <ul class="list">
                                <h3>Types of business loan</h3>
                                <li>Secured loans</li>
                                <li>Unsecured loans</li>
                                <li>Revolving credit facilities</li>
                                <li>Business cash advances</li>
                            </ul>
                            <div class="services-tab-content">
                                <h3>Eligibility and criteria for Agricultural loan</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                            </div>
                            <div class="services-tab-content left">
                                <h3>Agricultural loan guide</h3>
                                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Clients words</span>
            <h2>What our clients say</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="clients-slider owl-carousel owl-theme">
            <div class="clients-item">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="clients-info-text">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                            <h3>Debra C. Cowen</h3>
                            <span>Founder</span>
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="clients-image">
                            <img src="assets/img/clients/man.png" alt="image">
                            <div class="icon-1">
                                <i class="flaticon-right-quote"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clients-item">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="clients-info-text">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                            <h3>Debra C. Cowen</h3>
                            <span>Founder</span>
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="clients-image">
                            <img src="assets/img/clients/man.png" alt="image">
                            <div class="icon-1">
                                <i class="flaticon-right-quote"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clients-item">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="clients-info-text">
                            <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.”</p>
                            <h3>Debra C. Cowen</h3>
                            <span>Founder</span>
                        </div>
                    </div>
                    
                    <div class="col-lg-6">
                        <div class="clients-image">
                            <img src="assets/img/clients/man.png" alt="image">
                            <div class="icon-1">
                                <i class="flaticon-right-quote"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Expert People</span>
            <h2>Global leadership team</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/image1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Jerome Le Luel</h3>
                        <span>Managing Director</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/image2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Andrew Learoyd</h3>
                        <span>Chief Executive Officer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/image3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Harry Nelis</h3>
                        <span>Technology Officer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-item">
                    <div class="image">
                        <img src="assets/img/team/image4.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Lucy Vernall</h3>
                        <span>Chief Financial Officer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Recent news</span>
            <h2>Success story posts</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/image1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <span>July 05, 2024</span>
                        <h3><a routerLink="/blog-details">Financing loans available to small businesses</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/image2.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <span>July 04, 2024</span>
                        <h3><a routerLink="/blog-details">5 Ways you can prepare your business for success</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="blog-item">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/image3.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <span>July 03, 2024</span>
                        <h3><a routerLink="/blog-details">Tips for saving money and better guide for investment</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>