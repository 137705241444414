<section class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo2.png" alt="image"></a>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consec tetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="social">
                        <li><b>Follow us:</b></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/">Our Performance</a></li>
                        <li><a routerLink="/faq">Help (FAQ)</a></li>
                        <li><a routerLink="/blog">Blog</a></li>
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>    
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Other Resources</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-conditions">Terms of Service</a></li>
                        <li><a routerLink="/">Business Loans</a></li>
                        <li><a routerLink="/">Loan Services</a></li>
                    </ul>
                </div>    
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact Us</h3>

                    <div class="info-contact">
                        <i class="flaticon-pin"></i>
                        <span>6890 Blvd, The Bronx, NY 1058 New York, USA</span>
                    </div>
                    <div class="info-contact">
                        <i class="flaticon-mail"></i>
                        <span><a href="mailto:hello&#64;topli.com">hello&#64;topli.com</a></span>
                        <span><a href="#">Skype: example</a></span>
                    </div>

                    <div class="info-contact">
                        <i class="flaticon-telephone"></i>
                        <span><a href="tel:1514312-6688">+1 (514) 312-5678</a></span>
                        <span><a href="tel:1514312-6688">+1 (514) 312-6688</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="copy-right-area">
    <div class="container">
        <div class="copy-right-content">
            <p>© Topli is Proudly Owned by <a href="https://envytheme.com/" target="_blank">EnvyTheme</a></p>
        </div>
    </div>
</div>

<div class="go-top">
    <i class='bx bx-chevron-up'></i>
</div>