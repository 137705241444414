<div class="page-title-area item-bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog Details</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="image">
                        <img src="assets/img/blog/image4.jpg" alt="image">
                    </div>

                    <ul class="post-meta">
                        <li><i class='bx bx-calendar'></i> July 05, 2024</li>
                        <li><i class='bx bx-user'></i> <a routerLink="/blog">Admin</a></li>
                        <li><i class='bx bx-comment'></i> No Comments</li>
                    </ul>

                    <div class="content">
                        <h3>Financing loans available to small businesses</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
                    </div>

                    <blockquote class="wp-block-quote">
                        <i class='bx bxs-quote-alt-left'></i>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </blockquote>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>

                    <div class="desc-text">
                        <h3>Types of small business loans</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
                    </div>

                    <div class="desc-text-one">
                        <h3>Other financing options</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
                    </div>

                    <div class="article-share">
                        <ul class="social">
                            <li><span>Share this post:</span></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>

                    <div class="comment-reply">
                        <h2 class="comment-reply-title">Leave a Reply</h2>

                        <form class="comment-form">
                            <p class="comment-notes">
                                <span id="email-notes">Your email address will not be published.</span>
                                Required fields are marked 
                                <span class="required">*</span>
                            </p>
                            <p class="comment-form-author">
                                <label>Name <span class="required">*</span></label>
                                <input type="text" id="author" name="author" required="required">
                            </p>
                            <p class="comment-form-email">
                                <label>Email <span class="required">*</span></label>
                                <input type="email" id="email" name="email" required="required">
                            </p>
                            <p class="comment-form-url">
                                <label>Website</label>
                                <input type="url" id="url" name="url">
                            </p>
                            <p class="comment-form-comment">
                                <label>Comment</label>
                                <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required"></textarea>
                            </p>
                            <p class="comment-form-cookies-consent">
                                <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                            </p>
                            <p class="form-submit">
                                <input type="submit" name="submit" id="submit" class="submit" value="Post a comment">
                            </p>
                        </form>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form search-top">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search Here">
                            </label>
                            <button type="submit"><i class='bx bx-search-alt'></i></button>
                        </form>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/blog">Agricultural loan</a></li>
                            <li><a routerLink="/blog">Business loan</a></li>
                            <li><a routerLink="/blog">House loan</a></li>
                            <li><a routerLink="/blog">Personal loan</a></li>
                            <li><a routerLink="/blog">Education loan</a></li>
                            <li><a routerLink="/blog">Payday Loan</a></li>
                            <li><a routerLink="/blog">Vehicle loan</a></li>
                            <li><a routerLink="/blog">Medical loan</a></li>
                            <li><a routerLink="/blog">StartUp loan</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_finix_posts_thumb">
                        <h3 class="widget-title">Recent Posts</h3>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">Customer experience more important </a></h4>
                                <time class="2024-06-30">06-07-2024</time>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">Start up loans here for the long time</a></h4>
                                <time class="2024-06-30">07-07-2024</time>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">Eligibility and criteria for business loans</a></h4>
                                <time class="2024-06-30">08-07-2024</time>
                            </div>
                        </article>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="tagcloud section-bottom">
                            <a routerLink="/blog">Business </a>
                            <a routerLink="/blog">Growth</a>
                            <a routerLink="/blog">Loan</a>
                            <a routerLink="/blog">Funds</a>
                            <a routerLink="/blog">Speed</a>
                            <a routerLink="/blog">Investment</a>
                            <a routerLink="/blog">Payment</a>
                        </div>
                    </section>

                    <section class="widget widget_archives">
                        <h3 class="widget-title">Archives</h3>

                        <ul>
                            <li><a routerLink="/blog">June 2024</a></li>
                            <li><a routerLink="/blog">May 2024</a></li>
                            <li><a routerLink="/blog">April 2024</a></li>
                            <li><a routerLink="/blog">March 2024</a></li>
                            <li><a routerLink="/blog">February 2024</a></li>
                            <li><a routerLink="/blog">January 2024</a></li>
                        </ul>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>