<div class="main-banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="main-banner-content">
                    <span>We are here for</span>
                    <h1>Start or grow your own business</h1>
                    <p>Loans are advantageous as a relatively inexpensive way of borrowing money. We believe in those made to do more.</p>
                    <div class="banner-btn">
                        <a routerLink="/apply-now" class="default-btn">View More<span></span></a>
                    </div>
                </div>

                <div class="banner-social-buttons">
                    <ul>
                        <li><span>Follow us</span></li>
                        <li><a href="#" target="_blank"><i class="flaticon-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="flaticon-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
    <div class="approvals-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="approvals-content">
                        <div class="icon">
                            <i class="flaticon-loan"></i>
                        </div>
                        <span>Quick</span>
                        <p>Loan approvals</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="approvals-content">
                        <div class="icon">
                            <i class="flaticon-satisfaction"></i>
                        </div>
                        <span>20,000</span>
                        <p>Customers satisfaction</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="approvals-content">
                        <div class="icon">
                            <i class="flaticon-document"></i>
                        </div>
                        <span>No prepayment or</span>
                        <p>hidden fees</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="about-image">
                    <img src="assets/img/about/image1.jpg" alt="image">
                    <img src="assets/img/about/image2.jpg" alt="image">
                </div>
                <div class="experience">
                    <h4>25</h4>
                    <p>We have more than years of experience</p>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="about-content">
                    <span>About company</span>
                    <h3>We have been working very efficiently with loan and funding for 25 years.</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <strong>In business, we focus on 3 things.</strong>
                    <ul class="about-list">
                        <li><i class="flaticon-check"></i> Useful info</li>
                        <li><i class="flaticon-check"></i> Reliability</li>
                        <li><i class="flaticon-check"></i> Innovation</li>
                    </ul>
                    <div class="about-btn">
                        <a routerLink="/about" class="default-btn">View More<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Transparent process</span>
            <h2>Why people choose us</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="process-item">
                    <div class="icon">
                        <i class="flaticon-guarantee"></i>
                    </div>
                    <h3>Guarantee</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="process-item">
                    <div class="icon">
                        <i class="flaticon-speed"></i>
                    </div>
                    <h3>Speed</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="process-item">
                    <div class="icon">
                        <i class="flaticon-reliability"></i>
                    </div>
                    <h3>Reliability</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="process-item">
                    <div class="icon">
                        <i class="flaticon-user-experience"></i>
                    </div>
                    <h3>Experience</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="company-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7">
                <div class="company-image">
                    <img src="assets/img/company.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-5">
                <div class="company-content">
                    <h3>Our company values</h3>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-idea"></i>
                        </div>
                        <h4>Innovative</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-talent"></i>
                        </div>
                        <h4>Talent</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-key"></i>
                        </div>
                        <h4>Enabling</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="company-text">
                        <div class="icon">
                            <i class="flaticon-responsibility"></i>
                        </div>
                        <h4>Commercially responsible</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Knowledge of the market</span>
            <h2>Only the best professional services</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-agriculture"></i>
                    </div>
                    <h3>Agricultural loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-loan-1"></i>
                    </div>
                    <h3>Business loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-loan-2"></i>
                    </div>
                    <h3>House loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-personal"></i>
                    </div>
                    <h3>Personal loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-scholarship"></i>
                    </div>
                    <h3>Education loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-item">
                    <div class="icon">
                        <i class="flaticon-loan-3"></i>
                    </div>
                    <h3>Payday loan</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ncid dunt ut labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="learn-btn">Learn More</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="deserve-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Funding circles</span>
            <h2>You deserve a better business loan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="deserve-item">
                    <h3>We usually follow 4 steps to get a better business loans.</h3>

                    <div class="deserve-content">
                        <span>1</span>
                        <h4>Apply in 10 minutes</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-content">
                        <span>2</span>
                        <h4>Hear from us in 1 hour</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-content">
                        <span>3</span>
                        <h4>A decision in 24 hours</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-content">
                        <span>4</span>
                        <h4>Your loan is funded</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                    <div class="deserve-btn">
                        <a routerLink="/apply-now" class="default-btn">Apply Now<span></span></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="rate-form">
                    <div class="rate-content">
                        <span>Calculate your rate</span>
                        <h3>How much do you need?</h3>
                    </div>

                    <div class="form">
                        <div class="form-group mb-3">
                            <label>Type The Money</label>
                            <input type="text" class="form-control" placeholder="$1000">
                        </div>

                        <div class="form-group mb-3">
                            <label>Select The Month/Years</label>
                            <div class="select-box">
                                <select class="form-control">
                                    <option value="5">1 month</option>
                                    <option value="1">2 month</option>
                                    <option value="2">3 month</option>
                                    <option value="0">4 month</option>
                                    <option value="3">6 month</option>
                                    <option value="3">1 Year</option>
                                    <option value="0">2 Years</option>
                                    <option value="3">3 Years</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group mb-3">
                            <label>Borrowing</label>
                            <input type="text" class="form-control" placeholder="$10000">
                        </div>

                        <div class="form-group mb-3">
                            <label>Term of use</label>
                            <input type="text" class="form-control" placeholder="6 month">
                        </div>

                        <div class="form-group mb-3">
                            <label>The total you will pay</label>
                            <input type="text" class="form-control" placeholder="$11200">
                        </div>

                        <div class="rate-btn">
                            <button type="submit" class="default-btn">Apply for this loan<span></span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="projects-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Our projects</span>
            <h2>All the work that we do</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="projects-slider owl-carousel owl-theme">
            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image1.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Financial Planning</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image2.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Startup Funding</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image3.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Fund Management</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image4.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Investment Shares</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image1.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Financial Planning</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image2.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Startup Funding</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a href="#"><img src="assets/img/projects/image3.jpg" alt="image"></a>

                <div class="content">
                    <h3><a href="#">Fund Management</a></h3>
                </div>
            </div>

            <div class="projects-item">
                <a routerLink="/project-details"><img src="assets/img/projects/image4.jpg" alt="image"></a>

                <div class="content">
                    <h3><a routerLink="/project-details">Investment Shares</a></h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="clients-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Clients words</span>
            <h2>What our clients say</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="clients-item">
                    <div class="info">
                        <img src="assets/img/clients/image1.png" alt="image">
                        <h4>Markus Twain</h4>
                        <span>Client</span>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <div class="icon">
                        <i class="flaticon-right-quote"></i>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="clients-item">
                    <div class="info">
                        <img src="assets/img/clients/image2.png" alt="image">
                        <h4>Jessica Molony</h4>
                        <span>CEO</span>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    <div class="icon">
                        <i class="flaticon-right-quote"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/1.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/2.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/3.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/4.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/5.png" alt="image">
            </div>
        </div>
    </div>
</div>

<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Recent news</span>
            <h2>Success story posts</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/image1.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <span>July 05, 2024</span>
                        <h3><a routerLink="/blog-details">Financing loans available to small businesses</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-item">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/image2.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <span>July 04, 2024</span>
                        <h3><a routerLink="/blog-details">5 Ways you can prepare your business for success</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="blog-item">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/image3.jpg" alt="image">
                        </a>
                    </div>

                    <div class="content">
                        <span>July 03, 2024</span>
                        <h3><a routerLink="/blog-details">Tips for saving money and better guide for investment</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>