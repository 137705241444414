<div class="page-title-area item-bg-8">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>FAQ</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Faq</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Frequently ask question</h2>
            <b>Just find your answers below</b>
        </div>

        <div class="tab faq-list-tab">
            <ul class="tabs-list">
                <li><a href="#">Top Question</a></li>
                <li><a href="#">General Question</a></li>
                <li><a href="#">About Topli</a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="faq-accordion">
                        <ul class="accordion">
                            <li class="accordion-item active">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> What is State Aid?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> Why do you charge interest on the loan?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> Can I apply for a loan if I have poor credit?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> How long does the application process take?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> My business internationally. Am I still eligible to apply?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> What kind of financial advice do you give?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="faq-accordion">
                        <ul class="accordion">
                            <li class="accordion-item active">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> What is State Aid?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> Why do you charge interest on the loan?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> Can I apply for a loan if I have poor credit?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> How long does the application process take?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> My business internationally. Am I still eligible to apply?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> What kind of financial advice do you give?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="faq-accordion">
                        <ul class="accordion">
                            <li class="accordion-item active">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> What is State Aid?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> Why do you charge interest on the loan?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> Can I apply for a loan if I have poor credit?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> How long does the application process take?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> My business internationally. Am I still eligible to apply?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                            <li class="accordion-item">
                                <a class="accordion-title" href="javascript:void(0)"><i class="flaticon-plus"></i> What kind of financial advice do you give?</a>
                                <p class="accordion-content">Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor massa sociis natoque penatibus. ipsum dolor consectetur Fusce varius Fusce varius euismod lacus eget feugiat...</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="faq-contact">
            <div class="section-title">
                <h2>Still, have questions?</h2>
            </div>

            <div class="faq-contact-form">
                <form id="contactForm">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="text" name="name" class="form-control" placeholder="Your Name">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="form-group mb-3">
                                <input type="email" name="email" class="form-control" placeholder="Email">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group mb-3">
                                <textarea name="message" class="form-control" cols="30" rows="5" placeholder="Text Here"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12">
                            <button type="submit" class="default-btn">Submit Now<span></span></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>