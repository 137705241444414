<div class="page-title-area item-bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Blog</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Blog</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog">
                            <div class="image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/image4.jpg" alt="image"></a>
                            </div>
                            <ul class="post-meta">
                                <li><i class='bx bx-calendar'></i> July 05, 2024</li>
                                <li><i class='bx bx-user'></i> <a routerLink="/blog">Admin</a></li>
                                <li><i class='bx bx-comment'></i> No Comments</li>
                            </ul>
                            <div class="content">
                                <h3><a routerLink="/blog-details">Financing loans available to small businesses</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                <div class="blog-btn">
                                    <a routerLink="/blog-details" class="default-btn">Read More<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog">
                            <div class="image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/image5.jpg" alt="image"></a>
                            </div>
                            <ul class="post-meta">
                                <li><i class='bx bx-calendar'></i> July 04, 2024</li>
                                <li><i class='bx bx-user'></i> <a routerLink="/blog">Admin</a></li>
                                <li><i class='bx bx-comment'></i> No Comments</li>
                            </ul>
                            <div class="content">
                                <h3><a routerLink="/blog-details">5 Ways you can prepare your business for success</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                <div class="blog-btn">
                                    <a routerLink="/blog-details" class="default-btn">Read More<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog">
                            <div class="image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/image6.jpg" alt="image"></a>
                            </div>
                            <ul class="post-meta">
                                <li><i class='bx bx-calendar'></i> July 03, 2024</li>
                                <li><i class='bx bx-user'></i> <a routerLink="/blog">Admin</a></li>
                                <li><i class='bx bx-comment'></i> No Comments</li>
                            </ul>
                            <div class="content">
                                <h3><a routerLink="/blog-details">Tips for saving money and better  guide for investment</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                <div class="blog-btn">
                                    <a routerLink="/blog-details" class="default-btn">Read More<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="single-blog">
                            <div class="image">
                                <a routerLink="/blog-details"><img src="assets/img/blog/image7.jpg" alt="image"></a>
                            </div>
                            <ul class="post-meta">
                                <li><i class='bx bx-calendar'></i> July 02, 2024</li>
                                <li><i class='bx bx-user'></i> <a routerLink="/blog">Admin</a></li>
                                <li><i class='bx bx-comment'></i> No Comments</li>
                            </ul>
                            <div class="content">
                                <h3><a routerLink="/blog-details">Need financial help to open again your business</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.  At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                <div class="blog-btn">
                                    <a routerLink="/blog-details" class="default-btn">Read More<span></span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area">
                            <a routerLink="/blog" class="page-numbers current">1</a>
                            <a routerLink="/blog" class="page-numbers">2</a>
                            <a routerLink="/blog" class="page-numbers">3</a>
                            <a routerLink="/blog" class="page-numbers">4</a>
                            <a routerLink="/blog" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form search-top">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search Here">
                            </label>
                            <button type="submit"><i class='bx bx-search-alt'></i></button>
                        </form>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/blog">Agricultural loan</a></li>
                            <li><a routerLink="/blog">Business loan</a></li>
                            <li><a routerLink="/blog">House loan</a></li>
                            <li><a routerLink="/blog">Personal loan</a></li>
                            <li><a routerLink="/blog">Education loan</a></li>
                            <li><a routerLink="/blog">Payday Loan</a></li>
                            <li><a routerLink="/blog">Vehicle loan</a></li>
                            <li><a routerLink="/blog">Medical loan</a></li>
                            <li><a routerLink="/blog">StartUp loan</a></li>
                        </ul>
                    </section>

                    <section class="widget widget_finix_posts_thumb">
                        <h3 class="widget-title">Recent Posts</h3>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">Customer experience more important </a></h4>
                                <time class="2024-06-30">06-07-2024</time>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">Start up loans here for the long time</a></h4>
                                <time class="2024-06-30">07-07-2024</time>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                            <div class="info">
                                <h4 class="title usmall"><a routerLink="/blog-details">Eligibility and criteria for business loans</a></h4>
                                <time class="2024-06-30">08-07-2024</time>
                            </div>
                        </article>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>

                        <div class="tagcloud section-bottom">
                            <a routerLink="/blog">Business </a>
                            <a routerLink="/blog">Growth</a>
                            <a routerLink="/blog">Loan</a>
                            <a routerLink="/blog">Funds</a>
                            <a routerLink="/blog">Speed</a>
                            <a routerLink="/blog">Investment</a>
                            <a routerLink="/blog">Payment</a>
                        </div>
                    </section>

                    <section class="widget widget_archives">
                        <h3 class="widget-title">Archives</h3>

                        <ul>
                            <li><a routerLink="/blog">June 2024</a></li>
                            <li><a routerLink="/blog">May 2024</a></li>
                            <li><a routerLink="/blog">April 2024</a></li>
                            <li><a routerLink="/blog">March 2024</a></li>
                            <li><a routerLink="/blog">February 2024</a></li>
                            <li><a routerLink="/blog">January 2024</a></li>
                        </ul>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>